.sizesSummaryTitle {
  font-size: 1.25rem;
  text-align: center;
  font-weight: bold;
  padding-bottom: 2rem;
}

.sizesSummaryItem {
  padding-bottom: 1rem;
  font-size: 1.1rem;
}

.sizesSummaryTotal {
  font-size: 1.25rem;
  padding-bottom: 1rem;
}