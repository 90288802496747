.shareNotify {
  font-size: 1rem;
}

.shareNotifyTitle {
  text-align: center;
  font-size: 1.25rem;
  padding-bottom: 1rem;
}

.shareNotifyDescription {
  padding-bottom: 1rem;
}