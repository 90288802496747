.loginUsersSeparator {
  height: 0.25px;
  background-color: var(--color-gray-500);
}

.loginUsersUser {
  /* overflow-x: hidden; */
  /* text-align: center; */
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.25rem;
  word-break: break-all;
  cursor: default;
}

.loginUsersEmail {
  text-align: center;
  padding: 0.25rem;
  cursor: default;
  /* padding-bottom: 0.5rem; */
}

.loginUsersSelect {
  text-align: center;
  /* font-size: 1rem; */
  padding-bottom: 0.5rem;
  cursor: default;
}

.loginUsersSelectButton {
  cursor: pointer;
  font-size: 1rem;
  padding: 0.1rem;
}