.authRequired {
    min-height: 100vh;
    padding: 1rem;
}

.authRequiredButtons {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 1rem 0;
}

.authRequiredAboutLink {
    font-size: 1.25rem;
}