.dropAreaContainer {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  /* border: 3px solid red; */
  background-color: var(--color-gray-20);
  /* padding: 1rem; */
  /* height: 90vh; */
  /* max-height: 90vh; */

  position: fixed;
  left: 5%;
  right: 5%;
  top: 5vh;
  bottom: 5vh;
  z-index: 200;

  overflow: auto;
  /* left: calc(50% - 15rem); */
}

.dropArea {
  height: 70%; 
  width: 95%;
  margin: 1rem auto;
  border: 4px dashed var(--color-gray-300);
}

.dropArea:hover {
  background-color: var(--color-gray-50);
}

.dropAreaContent {
  /* padding-top: 30%; */
  padding-top: 5rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--color-gray-300);
}

.dropAreaHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* text-align: right; */
  padding: 1rem;
}

.dropAreaCloseButton {
  font-size: 1.5rem;
  /* font-weight: bold; */
  cursor: pointer;
}