.trashSelectedControl {
  display: flex;
  justify-content: space-between;
}

.trashSelectedContainer {
  margin: 0.5rem;
  box-shadow: var(--shadow-medium);
  border: 1px solid var(--color-gray-100);
  border-radius: 2px;
  min-width: 12.5rem;
}

.trashSelectedTitle {
  padding: 0.5rem;
  text-align: center;
}

.trashSelectedTitleSeparator {
  background-color: var(--color-gray-300);
  height: 1px;
}


.trashActionItemContainer {
  /* max-width: 75%;  */
  /* padding-top: 1rem; */
}

.trashActionItemContainer :hover {
  background-color: var(--color-gray-50);
}

.trashActionItem {
  padding: 0 0 1rem 1rem;
  padding: 1rem;
  cursor: pointer;
}