
.objectsActionsContainer {
  margin: 0.5rem;
  box-shadow: var(--shadow-medium);
  border: 1px solid var(--color-gray-100);
  border-radius: 2px;
  min-width: 12.5rem;
}

.objectsActionsTitle {
  padding: 0.5rem;
  text-align: center;
}

.objectsActionsTitleSeparator {
  background-color: var(--color-gray-300);
  height: 1px;
}


.objectsActionItemContainer {
  /* max-width: 75%;  */
  padding-top: 1rem;
}

.objectsActionItemContainer :hover {
  background-color: var(--color-gray-50);
}

.objectsActionsItem {
  padding: 0 0 1rem 1rem;
  cursor: pointer;
}