.footer {
  height: 3rem;
  border-top: 0.5px solid gray;
  padding: 0 0.5rem;
}

.footerLinks {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  /* margin-top: 0.5rem; */
  font-size: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.footerLink {
  /* padding: 0.5rem; */
}