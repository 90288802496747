.folderTable {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid var(--color-gray-100);
  border-radius: 4px;
}

.folderTableFolderName {
  padding-left: 1rem;
}