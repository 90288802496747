
.objectControlModal {
  /* padding: 0.5rem; */
  box-shadow: var(--shadow-medium);
  border: 1px solid var(--color-gray-100);
  border-radius: 2px;
}

.objectControlModal :hover {
  background-color: var(--color-gray-50);
}

.objectControlListItem {
  padding: 0.5rem 0.5rem;
  /* cursor: pointer; */
}

.objectControlButton {
  cursor: pointer;
  padding-left: 0.5rem;
  font-weight: bold;
}

.objectControlLink {
  font-weight: normal;
  text-decoration: none;
  color: inherit;
}

.objectControlListItemContainer {
  position: relative;
}

.objectControlListItemNotifyMark {
  position: absolute; 
  top: 0; 
  right: 0; 
  padding: 2px;
  color: white; 
  background: red;
  border-radius: 2rem;
}



/* .modalCloseMark {
  text-align: right;
}
.modalCloseMarkButton {
  cursor: pointer;
} */