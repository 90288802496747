.folderNameInput {
  /* padding: 0.5rem;
  border: 1px solid var(--color-gray-500);
  border-radius: 2px; */
  margin-bottom: 2rem;
}

.folderNameLabel {
  padding-bottom: 1rem;
}

