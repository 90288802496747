
.fileSharingUsersSection {
  padding-bottom: 1rem;
}

.fileSharingUsersList {
  font-size: 1rem;
}

.fileSharingUsersUserImage {
  height: 1rem;
  width: 1rem;
  object-fit: cover;
  border-radius: 2px;
}