.modalClose {
  text-align: right;
}

.modalCloseButton {
  font-size: 1.5rem;
  cursor: pointer;
}

.modalConfirmMessage {
  padding: 1rem;
  font-size: 1.25rem;
}

.modalConfirmActionButtons {
  display: flex;
  gap: 2rem;
  padding: 1rem 0.5rem 0.5rem 0.5rem;
}