.fileNameInput {
  /* padding: 0.5rem;
  border: 1px solid var(--color-gray-500);
  border-radius: 2px; */
  margin-bottom: 1rem;
}

.fileNameChangeButtons {
  display: flex;
  gap: 1rem;
}