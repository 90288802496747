.editLinkModal {
  font-size: 1rem;
}

.editLinkSection {
  padding-bottom: 1rem;
}

.editLinkTitle {
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
}

.editLinkImage {
  height: 75px;
  max-width: 100%;
}

.editLinkIpaImageList {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-bottom: 1rem;
}

/* .editLinkIpaImageContainer {
  display: flex;
  flex-direction: column;

} */

.editLinkIpaImage {
  height: 50px;
  max-width: 100%;
}