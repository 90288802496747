.notPageFoundContent {
  padding: 1rem;
}

.notPageFoundButtons {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem 0;
}

.notPageFoundAboutLink {
  font-size: 1.25rem;
}