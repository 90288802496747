.sizeManage {
  padding: 5rem 0.5rem 2rem 0.5rem;
}

.sizeManageInfo {
  padding-bottom: 1rem;
  font-size: 1.1rem;
}

.sizeManagePlanTitle {
  text-align: center;
  font-size: 1.5rem;
  padding-bottom: 1rem;
  font-weight: bold;
}

.sizeManageLink {
  font-size: 1.25rem;
  font-weight: bold;
}