.navUserIcon {
  font-size: 2rem;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-left: 1rem;
  cursor: pointer;
}

.navUserUserImageContainer {
  display: flex; 
  flex-direction: column;
  align-items: center;
}

.navUserUserImage {
  height: 1.25rem; 
  width: 1.25rem;
  object-fit: cover; 
  border-radius: 2px;
}

.navUserUserNoImage {
  font-size: 1.25rem;
}

.navUserUserName {
  font-size: 0.75rem;
}