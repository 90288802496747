.objectActionModal {
  /* padding: 0.5rem; */
  /* box-shadow: var(--shadow-medium);
  border: 1px solid var(--color-gray-100);
  border-radius: 2px; */
  display:flex; 
  flex-direction:column;
}

.objectActionModal :hover {
  background-color: var(--color-gray-100);
}

.objectActionListItem {
  background: white;
  font-size: 1rem;
  text-align: left;
  /* padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; */
  padding: 1rem 0.5rem;
  box-shadow: none;
  /* cursor: pointer; */

  /* padding: 0.25rem 0.5rem; */
  font: inherit;
  /* font-size: 1.1rem; */
  border: 0px;
  /* border-radius: 2px; */
  /* box-shadow: var(--shadow-small); */
}

.objectActionAudio {
  max-width: 100%;
  max-height: 300px;
}