.shareNotifyItem {
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border: 0.1px solid gray;
  border-radius: 4px;
}

.shareNotifyItemRow {
  padding-bottom: 0.5rem;
}

.shareNotifyItemActions {
  display: flex;
  justify-content: flex-end;
}