.fileShareModalContentRow {
  padding-bottom: 1.5rem;
}

.fileShareModalContentMessage {
  font-size: 1rem;
}

.fileShareModalContentLabel {
  font-size: 1rem;
}

.fileShareModalContentButtons {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}