.sizesContent {

}

.sizesContentItem {
  /* padding-bottom: 0.25rem; */
}

.sizesContentForModal {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  cursor: pointer;
}

