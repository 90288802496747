.folderTable {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid var(--color-gray-100);
  border-radius: 4px;
}

.folderTableFolderName {
  /* padding-left: 1rem; */
  word-break: break-all;
  /* cursor: pointer; */
}

.folderTableTopFileName {
  /* padding-left: 1rem; */
  /* padding-top: 0.5rem;
  padding-bottom: 0.5rem; */
  word-break: break-all;
  /* cursor: pointer; */
}

.folderTableFileName {
  /* padding-left: 2rem; */
  /* padding-top: 0.5rem;
  padding-bottom: 0.5rem; */
  word-break: break-all;
  /* cursor: pointer; */
}
